import Vue from "vue";
import VueRouter from "vue-router";
import StorageService from "@/services/storage";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Index.vue"),
      meta: {
        pageTitle: "Dashboard",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/users/Index.vue"),
      meta: {
        pageTitle: " کاربران",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "کاربران",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admins",
      name: "admins",
      component: () => import("@/views/admins/Index.vue"),
      meta: {
        pageTitle: "ادمین ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "ادمین ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/owners",
      name: "owners",
      component: () => import("@/views/owners/Index.vue"),
      meta: {
        pageTitle: "میزبان ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "میزبان ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/roles/Index.vue"),
      meta: {
        pageTitle: "مدیریت نقش ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "مدیریت نقش ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/clerks",
      name: "clerks",
      component: () => import("@/views/clerks/Index.vue"),
      meta: {
        pageTitle: "کارمندان/پرسنل",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "پرسنل",
            active: true,
          },
        ],
      },
    },
    {
      path: "/provinces",
      name: "provinces",
      component: () => import("@/views/provinces/Index.vue"),
      meta: {
        pageTitle: "استان ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "استان ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cities",
      name: "cities",
      component: () => import("@/views/cities/Index.vue"),
      meta: {
        pageTitle: "شهر ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "شهر ها",
            active: true,
          },
        ],
      },
    },
/*    {
      path: "/cities/:id/:name/statistics",
      name: "citiesStatistics",
      component: () => import("@/views/cities/statistics/Index.vue"),
      meta: {
        pageTitle: "شهر ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "شهر ها",
            active: true,
          },
        ],
      },
    },*/



/*    {
      path: "/countries",
      name: "countries",
      component: () => import("@/views/country/Index.vue"),
      meta: {
        pageTitle: "کشور ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "کشور ها",
            active: true,
          },
        ],
      },
    },*/
/*
    {
      path: "/capitals",
      name: "capitals",
      component: () => import("@/views/capital/Index.vue"),
      meta: {
        pageTitle: "پایتخت ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "پایتخت ها",
            active: true,
          },
        ],
      },
    },
*/

    {
      path: "/slider",
      name: "slider",
      component: () => import("@/views/slider/Index.vue"),
      meta: {
        pageTitle: "اسلایدر",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "اسلایدر",
            active: true,
          },
        ],
      },
    },
    {
      path: "/poster",
      name: "poster",
      component: () => import("@/views/poster/Index.vue"),
      meta: {
        pageTitle: "پوستر",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "پوستر",
            active: true,
          },
        ],
      },
    },


/*    {
      path: "/service",
      name: "service",
      component: () => import("@/views/service/Index.vue"),
      meta: {
        pageTitle: "خدمات",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "خدمات",
            active: true,
          },
        ],
      },
    },*/
    {
      path: "/newsletters",
      name: "newsletters",
      component: () => import("@/views/newsletters/Index.vue"),
      meta: {
        pageTitle: "خبرنامه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "خبرنامه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/units",
      name: "units",
      component: () => import("@/views/residences/unit/Index.vue"),
      meta: {
        pageTitle: "واحدها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "واحدها",
            active: true,
          },
        ],
      },
    },
/*    {
      path: "/contactus",
      name: "contactus",
      component: () => import("@/views/contactus/Index.vue"),
      meta: {
        pageTitle: "خدمات",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "خدمات",
            active: true,
          },
        ],
      },
    },*/

    {
      path: "/comments/items",
      name: "commentsItems",
      component: () => import("@/views/comments/commentItems/Index.vue"),
      meta: {
        pageTitle: "آیتم های دیدگاه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آیتم های دیدگاه ها",
            active: true,
          },
        ],
      },
    },

    {
      path: "/comments",
      name: "allComments",
      component: () => import("@/views/comments/Index.vue"),
      meta: {
        pageTitle: "همه دیدگاه ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: " همه دیدگاه ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/comments/:type/:id",
      name: "specificTypeComments",
      component: () => import("@/views/comments/type/Index.vue"),
      meta: {
        pageTitle: " دیدگاه ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "  دیدگاه ها",
            active: true,
          },
        ],
      },
    },


    /*============Space====================*/
    {
      path: "/residences",
      name: "residences",
      component: () => import("@/views/residences/Index.vue"),
      meta: {
        pageTitle: "اقامتگاه ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "اقامتگاه ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residencesNew",
      name: "residencesNew",
      component: () => import("@/views/residences/new/Index.vue"),
      meta: {
        pageTitle: "جدید ترین اقامتگاه ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "جدیدترین اقامتگاه ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residencesUpdate",
      name: "residencesUpdate",
      component: () => import("@/views/residences/update/Index.vue"),
      meta: {
        pageTitle: "آخرین آپدیت اقامتگاه ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آخرین آپدیت اقامتگاه ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residences/:id/calender/edit",
      name: "calender",
      component: () => import("@/views/residences/calender/Index.vue"),
      meta: {
        pageTitle: "تقویم",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "تقویم",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residences/:id/:name/statistics",
      name: "statisticsOneSpace",
      component: () => import("@/views/residences/statistics/Index.vue"),
      meta: {
        pageTitle: "آمار اقامتگاه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار اقامتگاه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/calender",
      name: "calender-peak",
      component: () => import("@/views/calender/Index.vue"),
      meta: {
        pageTitle: "تقویم",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "روزهای پیک",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residences/categories",
      name: "categories",
      component: () => import("@/views/residences/categories/Index.vue"),
      meta: {
        pageTitle: "دسته بندی ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "دسته بندی ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residences/properties",
      name: "properties",
      component: () => import("@/views/residences/properties/Index.vue"),
      meta: {
        pageTitle: "ویژگی ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "ویژگی ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residences/packages",
      name: "packages",
      component: () => import("@/views/residences/packages/Index.vue"),
      meta: {
        pageTitle: "امکانات",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "امکانات",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residences/healthItems",
      name: "healthItems",
      component: () => import("@/views/residences/healthItems/Index.vue"),
      meta: {
        pageTitle: "اقلام بهداشتی",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "اقلام بهداشتی",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residences/autoimmunePackages",
      name: "autoimmunePackages",
      component: () => import("@/views/residences/autoimmunePackages/Index.vue"),
      meta: {
        pageTitle: "امکانات ایمنی",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "امکانات ایمنی",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residences/rules",
      name: "rules",
      component: () => import("@/views/residences/rules/Index.vue"),
      meta: {
        pageTitle: "قوانین لغو رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "قوانین لغو رزرو",
            active: true,
          },
        ],
      },
    },
    {
      path: "/residences/regulations",
      name: "regulations",
      component: () => import("@/views/residences/regulations/Index.vue"),
      meta: {
        pageTitle: "مقررات اقامتگاه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "مقررات اقامتگاه",
            active: true,
          },
        ],
      },
    },
    /*============End of Space====================*/

    /*============ Tour ====================*/
/*    {
      path: "/tours",
      name: "tours",
      component: () => import("@/views/tours/Index.vue"),
      meta: {
        pageTitle: "تورها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "تور ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tours/:id/prices/edit",
      name: "toursPrices",
      component: () => import("@/views/tours/prices/Index.vue"),
      meta: {
        pageTitle: "هزینه تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "هزینه تور",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: "/prices/:id",
    //   name: "tours-prices",
    //   component: () => import("@/views/tours/prices/Index.vue"),
    //   meta: {
    //     pageTitle: "هزینه تور",
    //     requiresAuth: true,
    //     breadcrumb: [
    //       {
    //         text: "هزینه تور",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/tours/categories",
      name: "toursCategories",
      component: () => import("@/views/tours/categories/Index.vue"),
      meta: {
        pageTitle: "دسته بندی تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "دسته بندی تور",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tours/ages",
      name: "toursAges",
      component: () => import("@/views/tours/ages/Index.vue"),
      meta: {
        pageTitle: "رده سنی تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رده سنی تور",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tours/rules",
      name: "toursRules",
      component: () => import("@/views/tours/rules/Index.vue"),
      meta: {
        pageTitle: "قوانین لغو رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "قوانین لغو رزرو",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tours/regulations",
      name: "toursRegulations",
      component: () => import("@/views/tours/regulations/Index.vue"),
      meta: {
        pageTitle: "مقررات تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "مقررات تور",
            active: true,
          },
        ],
      },
    },*/
    /*============End of Tour ====================*/

    /*============ Event ====================*/
/*    {
      path: "/events",
      name: "events",
      component: () => import("@/views/events/Index.vue"),
      meta: {
        pageTitle: "تجربه و تفریحی",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "تجربه و تفریحی",
            active: true,
          },
        ],
      },
    },
    {
      path: "/events/:id/ticket/edit",
      name: "eventsCalender",
      component: () => import("@/views/events/ticket/Index.vue"),
      meta: {
        pageTitle: "تیکت",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "تجربه ها",
            active: false,
            to:"/events"
          },
        
          {
            text: "تیکت",
            active: true,
          },
        ],
      },
    },
    {
      path: "/events/:id/ticket",
      name: "eventsTicket",
      component: () => import("@/views/events/ticket/Index.vue"),
      meta: {
        pageTitle: "تیکت",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "تیکت",
            active: true,
          },
        ],
      },
    },
    {
      path: "/events/:id/ticket/block",
      name: "eventsBlockCalender",
      component: () => import("@/views/events/ticket/block/Index.vue"),
      meta: {
        pageTitle: " تقویم تیکت",
        requiresAuth: true,
        breadcrumb: [
          {
            text: " تقویم تیکت",
            active: true,
          },
        ],
      },
    },
    {
      path: "/events/:id/:name/statistics",
      name: "statisticsOneEvents",
      component: () => import("@/views/events/statistics/Index.vue"),
      meta: {
        pageTitle: "آمار تفریحات",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار تفریحات",
            active: true,
          },
        ],
      },
    },
    {
      path: "/events/categories",
      name: "eventsCategories",
      component: () => import("@/views/events/categories/Index.vue"),
      meta: {
        pageTitle: "دسته بندی تجربه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "دسته بندی تجربه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/events/ages",
      name: "eventsAges",
      component: () => import("@/views/events/ages/Index.vue"),
      meta: {
        pageTitle: "رده سنی تجربه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رده سنی تجربه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/events/rules",
      name: "eventsRules",
      component: () => import("@/views/events/rules/Index.vue"),
      meta: {
        pageTitle: "قوانین لغو رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "قوانین لغو رزرو",
            active: true,
          },
        ],
      },
    },
    {
      path: "/events/regulations",
      name: "eventsRegulations",
      component: () => import("@/views/events/regulations/Index.vue"),
      meta: {
        pageTitle: "مقررات تجربه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "مقررات تجربه",
            active: true,
          },
        ],
      },
    },*/
    /*============End of Event ====================*/


    /*============ Car ====================*/
/*    {
      path: "/cars",
      name: "cars",
      component: () => import("@/views/cars/Index.vue"),
      meta: {
        pageTitle: "تورها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "تور ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cars/categories",
      name: "carsCategories",
      component: () => import("@/views/cars/categories/Index.vue"),
      meta: {
        pageTitle: "دسته بندی تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "دسته بندی تور",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cars/rules",
      name: "carsRules",
      component: () => import("@/views/cars/rules/Index.vue"),
      meta: {
        pageTitle: "قوانین لغو رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "قوانین لغو رزرو",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cars/regulations",
      name: "carsRegulations",
      component: () => import("@/views/cars/regulations/Index.vue"),
      meta: {
        pageTitle: "مقررات تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "مقررات تور",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cars/:id/ticket/block",
      name: "carsBlockCalender",
      component: () => import("@/views/cars/ticket/block/Index.vue"),
      meta: {
        pageTitle: "تقویم تیکت",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "تقویم تیکت",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cars/:id/ticket/edit",
      name: "carsCalender",
      component: () => import("@/views/cars/ticket/Index.vue"),
      meta: {
        pageTitle: "تیکت",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "ترانسفر ",
            active: false,
            to:"/cars"
          },

          {
            text: "تیکت",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cars/:id/:name/statistics",
      name: "statisticsOneCars",
      component: () => import("@/views/cars/statistics/Index.vue"),
      meta: {
        pageTitle: "آمار ترانسفر",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار ترانسفر",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cars/:id/calender/edit",
      name: "transfareCalender",
      component: () => import("@/views/cars/calender/Index.vue"),
      meta: {
        pageTitle: "تقویم",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "تقویم",
            active: true,
          },
        ],
      },
    },*/
    /*============End of Car ====================*/
    /*============Payment To Host ====================*/
    /*============CheckItems To Services ====================*/

/*    {
      path: "/checkItems/:id/:type/",
      name: "checkItems",
      component: () => import("@/views/checkItem/Index.vue"),
      meta: {
        pageTitle: "بررسی آیتم های خدمات",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آیتم های خدمات",
            active: true,
          },
        ],
      },
    },*/

    /*============End of Payment To Host ====================*/
/*    {
      path: "/paymentNotHost",
      name: "paymentNotHost",
      component: () => import("@/views/paymentHost/notPay/Index.vue"),
      meta: {
        pageTitle: " پرداخت نشده  به میزبان  ",
        requiresAuth: true,
        breadcrumb: [
          {
            text: " پرداختی نشده به میزبان  ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/paymentHost",
      name: "paymentHost",
      component: () => import("@/views/paymentHost/pay/Index.vue"),
      meta: {
        pageTitle: " پرداخت شده  به میزبان  ",
        requiresAuth: true,
        breadcrumb: [
          {
            text: " پرداختی شده به میزبان  ",
            active: true,
          },
        ],
      },
    },*/


    /*============reservations ====================*/
/*    {
      path: "/orderSpace",
      name: "orderSpace",
      component: () => import("@/views/orders/Index.vue"),
      meta: {
        pageTitle: "درخواست های رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "درخواست های رزرو اقامتگاه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/orderRequestSpace",
      name: "orderRequestSpace",
      component: () => import("@/views/orders/request/Index.vue"),
      meta: {
        pageTitle: "درخواست های رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "درخواست های رزرو اقامتگاه",
            active: true,
          },
        ],
      },
    },

    {
      path: "/orderCar",
      name: "orderCar",
      component: () => import("@/views/orders/car/Index.vue"),
      meta: {
        pageTitle: "درخواست های رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "درخواست های رزرو ترانسفر",
            active: true,
          },
        ],
      },
    },
    {
      path: "/orderRequestCar",
      name: "orderRequestCar",
      component: () => import("@/views/orders/request/car/Index.vue"),
      meta: {
        pageTitle: "درخواست های رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "درخواست های رزرو وسیله نقلیه",
            active: true,
          },
        ],
      },
    },



    {
      path: "/orderEvent",
      name: "orderEvent",
      component: () => import("@/views/orders/event/Index.vue"),
      meta: {
        pageTitle: "درخواست های رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "درخواست های رزرو تجربه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/orderRequestEvent",
      name: "orderRequestEvent",
      component: () => import("@/views/orders/request/event/Index.vue"),
      meta: {
        pageTitle: "درخواست های رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "درخواست های رزرو تجربه",
            active: true,
          },
        ],
      },
    },


    {
      path: "/orderTour",
      name: "orderTour",
      component: () => import("@/views/orders/tour/Index.vue"),
      meta: {
        pageTitle: "درخواست های رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "درخواست های رزرو تور گردشگری",
            active: true,
          },
        ],
      },
    },
    {
      path: "/orderRequestTour",
      name: "orderRequestTour",
      component: () => import("@/views/orders/request/tour/Index.vue"),
      meta: {
        pageTitle: "درخواست های رزرو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "درخواست های رزرو تور",
            active: true,
          },
        ],
      },
    },

    {
      path: "/booking",
      name: "booking",
      component: () => import("@/views/booking/Index.vue"),
      meta: {
        pageTitle: "رزروهای سایر خدمات",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رزروهای سایر خدمات",
            active: true,
          },
        ],
      },
    },

    {
      path: "/reservations",
      name: "reservations",
      component: () => import("@/views/reservations/Index.vue"),
      meta: {
        pageTitle: "همه رزروها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "همه رزروها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/reservationSpace",
      name: "reservationSpace",
      component: () => import("@/views/reservationsSpace/Index.vue"),
      meta: {
        pageTitle: "رزرو اقامتگاه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رزرو اقامتگاه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/reservationCar",
      name: "reservationCar",
      component: () => import("@/views/reservationsCar/Index.vue"),
      meta: {
        pageTitle: "رزرو ترانسفر",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رزرو ترانسفر",
            active: true,
          },
        ],
      },
    },
    {
      path: "/reservationEvent",
      name: "reservationEvent",
      component: () => import("@/views/reservationsEvent/Index.vue"),
      meta: {
        pageTitle: "رزرو تجربه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رزرو تجربه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/reservationTour",
      name: "reservationTour",
      component: () => import("@/views/reservationsTour/Index.vue"),
      meta: {
        pageTitle: "رزرو تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رزرو تور",
            active: true,
          },
        ],
      },
    },


    {
      path: "/offlineSpace",
      name: "offlineSpace",
      component: () => import("@/views/offline/space/Index.vue"),
      meta: {
        pageTitle: "رزرو اقامتگاه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رزرو اقامتگاه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/offlineCar",
      name: "offlineCar",
      component: () => import("@/views/offline/car/Index.vue"),
      meta: {
        pageTitle: "رزرو ترانسفر",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رزرو ترانسفر",
            active: true,
          },
        ],
      },
    },
    {
      path: "/offlineEvent",
      name: "offlineEvent",
      component: () => import("@/views/offline/event/Index.vue"),
      meta: {
        pageTitle: "رزرو تجربه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رزرو تجربه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/offlineTour",
      name: "offlineTour",
      component: () => import("@/views/offline/tour/Index.vue"),
      meta: {
        pageTitle: "رزرو تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "رزرو تور",
            active: true,
          },
        ],
      },
    },*/

    /*============End Of reservations ====================*/

/*    {
      path: "/adminSms",
      name: "adminSms",
      component: () => import("@/views/sms/Index.vue"),
      meta: {
        pageTitle: "ارسال sms",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "ارسال sms",
            active: true,
          },
        ],
      },
    },
    {
      path: "/smsMessage",
      name: "smsMessage",
      component: () => import("@/views/smsMessage/Index.vue"),
      meta: {
        pageTitle: "ارسال sms",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "ارسال sms",
            active: true,
          },
        ],
      },
    },*/

    {
      path: "/seoCity",
      name: "seoCity",
      component: () => import("@/views/seoCity/Index.vue"),
      meta: {
        pageTitle: "خدمات سئو",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "خدمات سئو",
            active: true,
          },
        ],
      },
    },

      /*========== statistics =========*/


    /*============OFF ====================*/

/*    {
      path: "/discountSpace",
      name: "discountSpace",
      component: () => import("@/views/discount/space/Index.vue"),
      meta: {
        pageTitle: "کد تخفیف اقامتگاه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "کد تخفیف اقامتگاه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/discountSpace/:id",
      name: "discountSpaceShow",
      component: () => import("@/views/discount/space/show/Index.vue"),
      meta: {
        pageTitle: "لیست کد تخفیف",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "لیست کد تخفیف",
            active: false,
            to:"/discountSpace"
          },

          {
            text: "لیست کد تخفیف",
            active: true,
          },
        ],
      },
    },
    {
      path: "/discountEvent",
      name: "discountEvent",
      component: () => import("@/views/discount/event/Index.vue"),
      meta: {
        pageTitle: "کد تخفیف تجربه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "کد تخفیف تجربه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/discountEvent/:id",
      name: "discountEventShow",
      component: () => import("@/views/discount/event/show/Index.vue"),
      meta: {
        pageTitle: "لیست کد تخفیف",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "لیست کد تخفیف",
            active: false,
            to:"/discountEvent"
          },

          {
            text: "لیست کد تخفیف",
            active: true,
          },
        ],
      },
    },
    {
      path: "/discountTour",
      name: "discountTour",
      component: () => import("@/views/discount/tour/Index.vue"),
      meta: {
        pageTitle: "کد تخفیف تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "کد تخفیف تور",
            active: true,
          },
        ],
      },
    },
    {
      path: "/discountTour/:id",
      name: "discountTourShow",
      component: () => import("@/views/discount/tour/show/Index.vue"),
      meta: {
        pageTitle: "لیست کد تخفیف",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "لیست کد تخفیف",
            active: false,
            to:"/discountTour"
          },

          {
            text: "لیست کد تخفیف",
            active: true,
          },
        ],
      },
    },
    {
      path: "/discountCar",
      name: "discountCar",
      component: () => import("@/views/discount/car/Index.vue"),
      meta: {
        pageTitle: "کد تخفیف ترانسفر",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "کد تخفیف ترانسفر",
            active: true,
          },
        ],
      },
    },
    {
      path: "/discountCar/:id",
      name: "discountCarShow",
      component: () => import("@/views/discount/car/show/Index.vue"),
      meta: {
        pageTitle: "لیست کد تخفیف",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "لیست کد تخفیف",
            active: false,
            to:"/discountCar"
          },

          {
            text: "لیست کد تخفیف",
            active: true,
          },
        ],
      },
    },*/

    /*========== OFF =========*/

/*    {
      path: "/statistics",
      name: "statistics",
      component: () => import("@/views/statistics/Index.vue"),
      meta: {
        pageTitle: "آمار کلی",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار کلی",
            active: true,
          },
        ],
      },
    },
    {
      path: "/statistics/users",
      name: "statisticsUsers",
      component: () => import("@/views/statistics/users/Index.vue"),
      meta: {
        pageTitle: "آمار کاربران",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار کاربران",
            active: true,
          },
        ],
      },
    },
    {
      path: "/statistics/users/week",
      name: "statisticsUsersWeek",
      component: () => import("@/views/statistics/users/week/Index.vue"),
      meta: {
        pageTitle: "آمار کاربران هفته گذشته",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار کاربران هفته گذشته",
            active: true,
          },
        ],
      },
    },
    {
      path: "/statistics/users/month",
      name: "statisticsUsers",
      component: () => import("@/views/statistics/users/month/Index.vue"),
      meta: {
        pageTitle: "آمار کاربران یک ماه گذشته",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار کاربران یک ماه گذشته",
            active: true,
          },
        ],
      },
    },
    {
      path: "/statistics/residences",
      name: "statisticsResidences",
      component: () => import("@/views/statistics/residences/Index.vue"),
      meta: {
        pageTitle: "آمار اقامتگاه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار اقامتگاه",
            active: true,
          },
        ],
      },
    },

    {
      path: "/statistics/cars",
      name: "statisticsCars",
      component: () => import("@/views/statistics/cars/Index.vue"),
      meta: {
        pageTitle: "آمار ترانسفر",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار ترانسفر",
            active: true,
          },
        ],
      },
    },

    {
      path: "/statistics/experiences",
      name: "statisticsExperiences",
      component: () => import("@/views/statistics/experiences/Index.vue"),
      meta: {
        pageTitle: "آمار تجربه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار تجربه",
            active: true,
          },
        ],
      },
    },

    {
      path: "/statistics/tours",
      name: "statisticsTours",
      component: () => import("@/views/statistics/tours/Index.vue"),
      meta: {
        pageTitle: "آمار تور",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار تور",
            active: true,
          },
        ],
      },
    },
    {
      path: "/categorySpace/:id/:name/statistics",
      name: "categorySpaceStatistics",
      component: () => import("@/views/residences/categories/statistics/Index.vue"),
      meta: {
        pageTitle: "امار اقامتگاه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "امار اقامتگاه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/categoryEvent/:id/:name/statistics",
      name: "categoryEventStatistics",
      component: () => import("@/views/events/categories/statistics/Index.vue"),
      meta: {
        pageTitle: "آمار تجربه",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "آمار تجربه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/categoryTour/:id/:name/statistics",
      name: "categoryTourStatistics",
      component: () => import("@/views/tours/categories/statistics/Index.vue"),
      meta: {
        pageTitle: "شهر ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "شهر ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/categoryCar/:id/:name/statistics",
      name: "categoryCarStatistics",
      component: () => import("@/views/cars/categories/statistics/Index.vue"),
      meta: {
        pageTitle: "شهر ها",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "شهر ها",
            active: true,
          },
        ],
      },
    },*/
      /*============ End Of  statistics ============*/

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        guest: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        requiresAuth: false,
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  let token = StorageService.getToken();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (!token) {
      next();
    } else {
      next({ name: "home" });
    }
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
