import { $themeBreakpoints } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,

    /*==========Space===============*/
    autoimmune_packages_state: {},
    regulations_state: {},
    rules_state: {},
    packages_state: {},
    categories_state: {},
    health_items_state: {},

    /*===============Car=============*/
    regulations_car_state: {},
    rules_car_state: {},
    categories_car_state: {},

    /*======Tour===============*/
    regulations_tour_state: {},
    rules_tour_state: {},
    categories_tour_state: {},
    ages_tour_state: {},

    /*======Experience===============*/
    regulations_experience_state: {},
    rules_experience_state: {},
    categories_experience_state: {},
    ages_experience_state: {},
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    UPDATE_RESIDENCE_DATA_autoimmune_packages(state, val) {
      state.autoimmune_packages_state = val;
    },
    UPDATE_RESIDENCE_DATA_regulations(state, val) {
      state.regulations_state = val;
    },
    UPDATE_RESIDENCE_DATA_packages(state, val) {
      state.packages_state = val;
    },
    UPDATE_RESIDENCE_DATA_health_items(state, val) {
      state.health_items_state = val;
    },
    UPDATE_RESIDENCE_DATA_rules(state, val) {
      state.rules_state = val;
    },
    UPDATE_RESIDENCE_DATA_categories(state, val) {
      state.categories_state = val;
    },

/*===============Tour=================*/
    UPDATE_TOUR_DATA_regulations(state, val) {
      state.regulations_tour_state = val;
    },
    UPDATE_TOUR_DATA_rules(state, val) {
      state.rules_tour_state = val;
    },
    UPDATE_TOUR_DATA_categories(state, val) {
      state.categories_tour_state = val;
    },
    UPDATE_TOUR_DATA_ages(state, val) {
      state.ages_tour_state = val;
    },

    /*===============Car=================*/
    UPDATE_CAR_DATA_regulations(state, val) {
      state.regulations_car_state = val;
    },
    UPDATE_CAR_DATA_rules(state, val) {
      state.rules_car_state = val;
    },
    UPDATE_CAR_DATA_categories(state, val) {
      state.categories_car_state = val;
    },

    /*===============Experience=================*/
    UPDATE_EXPERIENCE_DATA_regulations(state, val) {
      state.regulations_experience_state = val;
    },
    UPDATE_EXPERIENCE_DATA_rules(state, val) {
      state.rules_experience_state = val;
    },
    UPDATE_EXPERIENCE_DATA_categories(state, val) {
      state.categories_experience_state = val;
    },
    UPDATE_EXPERIENCE_DATA_ages(state, val) {
      state.ages_experience_state = val;
    },





  },
  actions: {},
};
